import { EnumToArray } from '../../../utils/enumToArray';

enum Name {
  Petz = 'ptz',
  Seres = 'srs',
}

enum Variant {
  Primary = 'primary',
  Darker = 'darker',
}

enum BrandName {
  Seres = 'seres',
  AdotePetz = 'adotepetz',
  Petz = 'petz',
}

export const PTZBrandConfigList = {
  Name: EnumToArray([Name]),
  Variant: EnumToArray([Variant]),
  BrandName: EnumToArray([BrandName]),
};

export const PTZBrandConfig = {
  Name,
  Variant,
  BrandName,
};
